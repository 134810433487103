import React from 'react';
import './Footer.css';

class Footer extends React.Component {
    render() {
        return (
            <div className="Footer">
                <div>Copyright &#169; 2021 Emerald City Smoothie Bellingham</div>
                <div>Designed and Built by Willey Ohana</div>
            </div>
        )
    }
}

export default Footer;